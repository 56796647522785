<script setup lang="ts">
import { computed, reactive } from "vue";
import { useI18n } from "vue-i18n";
import { info } from "@sutton-signwriting/core/fsw/fsw";

import { signEditorEventBus } from "./eventBus";
import SignMaker from "./SignMaker/SignMaker.vue";
import SignPuddle from "./SignPuddle/SignPuddle.vue";
import SignPuddleModel from "./SignPuddle/model";
import { type SignMetadata } from "@/utils/types";

import SignWriting from "@/components/SignWriting/SignWriting.vue";

const { t } = useI18n();

/* eslint-disable @typescript-eslint/no-unused-vars */
const emit = defineEmits({
  create: (fsw?: string, terms?: string, text?: string, source?: string) =>
    true,
  update: (signs: { id: string; content: {} }[]) => true,
  close: () => true,
});
/* eslint-enable @typescript-eslint/no-unused-vars */

/**
 * This class represents the structure of a SignPuddle object as seen in the results shown
 * in the SignPuddle search by word. Except that "sign" is replaced by "fsw" for Formal Sign Writing.
 */
class SignMetadataModel implements SignMetadata {
  id: string;
  terms: string[];
  text: string;
  source: string;
  fsw: string;

  constructor({ terms = [], text = "", source = "", fsw = "" }) {
    this.terms = terms;
    this.text = text;
    this.source = source;
    this.fsw = fsw;
    this.id = "";
  }

  valid() {
    return this._validTerms() && this._validSource() && this._validFsw();
  }

  private _validTerms() {
    return this.terms.length > 0;
  }

  private _validSource() {
    return this.source.length > 0;
  }

  private _validFsw() {
    return this.fsw.length > 0 && info(this.fsw).segment !== "none";
  }
}

const state = reactive<{
  show: boolean;
  mode: "create" | "update";
  app: "signmaker" | "signpuddle";
  signMetadata: SignMetadataModel;
}>({
  show: false,
  mode: "create",
  app: "signmaker",
  signMetadata: new SignMetadataModel({}),
});

const signPuddleModel = computed(
  () => new SignPuddleModel({ input: state.signMetadata.terms[0] }),
);

function reset() {
  state.mode = "create";
  state.app = "signmaker";
  state.signMetadata.terms = [];
  state.signMetadata.text = "";
  state.signMetadata.source = "";
  state.signMetadata.fsw = "";
}

function open() {
  reset();
  state.show = true;
}

function close() {
  state.show = false;
  emit("close");
  reset();
}

function apply() {
  if (!state.signMetadata.valid()) {
    console.debug("SignEditor: apply: invalid inputs");
    return;
  }

  switch (state.mode) {
    case "create":
      emit(
        "create",
        state.signMetadata.fsw,
        state.signMetadata.terms.join(", "),
        state.signMetadata.text,
        state.signMetadata.source,
      );
      break;
    case "update":
      emit("update", [
        { id: state.signMetadata.id, content: state.signMetadata },
      ]);
      break;
  }

  close();
}

signEditorEventBus({
  activate: ({ mode, signs, app }) => {
    open();

    state.mode = mode;
    state.app = app;

    if (!signs || signs.length === 0) {
      console.debug("SignEditor: activate: empty signs");
      return;
    }

    state.signMetadata.id = signs[0].id;
    state.signMetadata.terms = (signs[0].content as any).terms;
    state.signMetadata.text = (signs[0].content as any).text;
    state.signMetadata.source = (signs[0].content as any).source;
    state.signMetadata.fsw = (signs[0].content as any).fsw;
  },
});
</script>
<template>
  <v-dialog v-model="state.show" :close-on-back="false" persistent>
    <div
      style="
        height: min-content;
        width: 900px;
        max-width: 85%;
        margin: auto;
        background-color: white;
        border-radius: 5px;
      "
    >
      <div class="sign-editor">
        <div style="padding: 0 1rem; max-width: 20rem">
          <div class="metadata-input">
            <p class="required">
              {{ t("signEditor.formulary.inputs.terms.label") }}
            </p>
            <v-combobox
              variant="outlined"
              chips
              multiple
              density="comfortable"
              :delimiters="[',']"
              v-model="state.signMetadata.terms"
              :placeholder="t('signEditor.formulary.inputs.terms.placeholder')"
            />
          </div>
          <div class="metadata-input">
            <p>{{ t("signEditor.formulary.inputs.text.label") }}</p>
            <v-textarea
              variant="outlined"
              rows="3"
              max-rows="3"
              auto-grow
              v-model="state.signMetadata.text"
              :placeholder="t('signEditor.formulary.inputs.text.placeholder')"
            />
          </div>
          <div class="metadata-input">
            <p class="required">
              {{ t("signEditor.formulary.inputs.source.label") }}
            </p>
            <v-text-field
              variant="outlined"
              v-model="state.signMetadata.source"
              :placeholder="t('signEditor.formulary.inputs.source.placeholder')"
            />
          </div>
          <div class="metadata-input">
            <p class="required">
              {{ t("signEditor.formulary.inputs.fsw.label") }}
            </p>
            <v-textarea
              variant="outlined"
              rows="3"
              max-rows="3"
              auto-grow
              v-model="state.signMetadata.fsw"
              :placeholder="t('signEditor.formulary.inputs.fsw.placeholder')"
            />
            <p style="font-size: small">Sign Preview</p>
            <div style="opacity: 0.5">
              <SignWriting :fsw="state.signMetadata.fsw" />
            </div>
          </div>
        </div>
        <SignMaker
          v-if="state.app === 'signmaker'"
          :fsw="state.signMetadata.fsw"
          @save="(fsw) => (state.signMetadata.fsw = fsw)"
        />
        <SignPuddle
          v-else
          :model="signPuddleModel"
          @update:fsw="(fsw) => (state.signMetadata.fsw = fsw)"
          @update:metadata="
            (value) => {
              state.signMetadata.terms = value.metadata.terms;
              state.signMetadata.text = value.metadata.text;
              state.signMetadata.source = value.metadata.source;
              state.signMetadata.fsw = value.metadata.fsw;
            }
          "
        />
      </div>
      <div
        class="sign-editor__actions"
        style="
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          padding: 1rem;
        "
      >
        <v-btn variant="outlined" text="cancel" @click="close" />
        <v-btn
          variant="outlined"
          text="Save"
          :disabled="!state.signMetadata.valid()"
          @click="apply"
        />
      </div>
    </div>
  </v-dialog>
</template>
<style scoped lang="scss">
.sign-editor {
  display: grid;
  grid-template-columns: max-content auto;
  padding: 1rem;
  gap: 1rem;
}

.metadata-input {
  p.required {
    &::after {
      content: " *";
      color: red;
    }
  }
}

@media screen and (max-width: 992px) {
  .sign-editor {
    display: flex;
    flex-direction: column;
  }
}
</style>
