import {
  SignPuddle3Client,
  SignPuddleMatch,
  type ApiConfigurations,
  type SignPuddleSearchEndPointResult,
} from "@/api/SignPuddle3Client";

export default class SignPuddleModel {
  apiConfigurations: ApiConfigurations = {
    match: SignPuddleMatch.Anywhere,
    countryCode: "BR",
    puddleCode: "sgn46",
    caseInsensitive: true,
    sort: {
      by: "created_at",
      order: "desc",
    },
  };
  private _signPuddleClient: SignPuddle3Client;
  private _signs: SignPuddleSearchEndPointResult[] | undefined = undefined;
  private _loading: boolean = false;
  input: string = "";

  constructor(args?: {
    apiConfigurations?: ApiConfigurations;
    signPuddleClient?: SignPuddle3Client;
    input?: string;
  }) {
    this.apiConfigurations = args?.apiConfigurations ?? this.apiConfigurations;
    this._signPuddleClient = args?.signPuddleClient ?? new SignPuddle3Client();
    this.input = args?.input ?? "";
  }

  get loading() {
    return this._loading;
  }

  get signs() {
    return this._signs ?? [];
  }

  private async searchTerm(term: string) {
    this._loading = true;
    const signs = await this._signPuddleClient.getSignsByTerm(
      term,
      this.apiConfigurations,
    );
    this._signs = signs.results.filter((sign) => sign.sign.length > 0);
    this._loading = false;
  }

  async load() {
    this.searchTerm(this.input);
  }
}
