import { wrapEventBus } from "@/utils/eventBus";
import mitt from "mitt";
import type ColumnItem from "../ContentManager/Paginator/Page/Column/ColumnItem/model";

// Event type
export type SignEditorEvent = {
  [activate: string]: {
    mode: "create" | "update";
    app: "signmaker" | "signpuddle";
    signs?: ColumnItem[];
  };
};

// Event emitter
export const signEditorEvent = mitt<SignEditorEvent>();

// Event listener
export const signEditorEventBus = wrapEventBus(signEditorEvent);
