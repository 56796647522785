<script setup lang="ts">
import { ref, watch } from "vue";
import { RouterView } from "vue-router";
import AlertComponent, {
  type AlertProps,
} from "@/components/AlertComponent/AlertComponent.vue";
import appStore from "@/stores/AppStore";
import { useTheme } from "vuetify";
import {
  addNotificationToAppAlertEventBus,
  type AppAlertEvent,
} from "./components/AlertComponent/eventBus";

const theme = useTheme();

function setVuetifyTheme(chosenTheme: "dark" | "light") {
  theme.global.name.value = chosenTheme;
}

setVuetifyTheme(appStore.state.theme.selected.value);

watch(
  () => appStore.state.theme.selected,
  (newValue) => {
    setVuetifyTheme(newValue.value);
  },
);

const infos = ref<AlertProps[]>([]);

function removeInfoById(id: string) {
  infos.value = infos.value?.filter((info) => info.id !== id);
}

function checkInfoExists(id: string) {
  return infos.value?.some((info) => info.id === id);
}

addNotificationToAppAlertEventBus({
  addNotificationToAppAlertEvent: (
    eventData: AppAlertEvent["addNotificationToAppAlertEvent"],
  ) => {
    checkInfoExists(eventData.id) || infos.value?.push(eventData);
  },
});
</script>
<template>
  <v-app>
    <v-main>
      <RouterView />
      <AlertComponent
        v-if="infos.length > 0"
        :infos="infos"
        @click:close="removeInfoById"
      />
    </v-main>
  </v-app>
</template>
