<script setup lang="ts">
import { computed } from "vue";
import { isFswType } from "@/utils/SignWritingUtilities";
import { fsw as fontTtf } from "@sutton-signwriting/font-ttf";

export type SignWritingProps = {
  fsw: string;
  fontSize?: number;
  color?: string;
};

const props = defineProps<SignWritingProps>();

const signToShow = computed(() => {
  const isPunctuation = isFswType(props.fsw, "punctuation");

  return isPunctuation
    ? fontTtf.symbolSvg(props.fsw)
    : fontTtf.signSvg(props.fsw);
});
</script>
<template>
  <div class="sign" v-html="signToShow" aria-hidden="true"></div>
</template>
<style scoped lang="scss">
.sign {
  width: min-content;
  margin: auto;
}
</style>
