<script setup lang="ts">
import { reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import {
  type ApiConfigurations,
  type PuddleInfo,
  SignPuddleMatch,
} from "@/api/SignPuddle3Client";
import signpuddle_countries from "@/assets/jsons/signpuddle_countries.json";
import { debounce } from "@/utils/utils";

const { t } = useI18n();

const props = defineProps<{
  apiConfigurations?: ApiConfigurations;
  focus?: boolean;
  input?: string;
}>();

const defaultApiConfigurations: ApiConfigurations = {
  match: SignPuddleMatch.Anywhere,
  countryCode: "BR",
  puddleCode: "sgn46",
  caseInsensitive: false,
  sort: {
    by: "created_at",
    order: "desc",
  },
};
const model = reactive(props.apiConfigurations ?? defaultApiConfigurations);
const inputElementRef = ref<HTMLInputElement | null>(null);
const input = ref(props.input ?? "");
const selectedSortBy = ref(
  props.apiConfigurations?.sort?.by ?? defaultApiConfigurations?.sort?.by,
);
const selectedSortOrder = ref(
  props.apiConfigurations?.sort?.order ?? defaultApiConfigurations?.sort?.order,
);
const selectedPuddle = ref({
  code: "sgn46",
  name: "Dicionário Brasil",
});
const selectedCountry = ref({
  name: "Brazil",
  code: "BR",
});

/* eslint-disable @typescript-eslint/no-unused-vars */
const emit = defineEmits({
  "update:input": (value: string) => true,
  "update:apiConfigurations": (value: ApiConfigurations) => true,
  "focus:in": () => true,
  "focus:out": () => true,
});
/* eslint-enable @typescript-eslint/no-unused-vars */

function itemPropsPuddles(item: PuddleInfo) {
  return {
    title: item.name,
    subtitle: "",
  };
}

function itemPropsCountryCode(item: { name: string; code: string }) {
  return {
    title: item.code,
    subtitle: item.name,
  };
}

function submit() {
  emit("update:apiConfigurations", {
    match: model.match,
    countryCode: selectedCountry.value.code,
    puddleCode: selectedPuddle.value.code,
    caseInsensitive: model.caseInsensitive,
    sort: {
      by: selectedSortBy.value,
      order: selectedSortOrder.value,
    },
    limit: model.limit,
  });
}

const debouncedInput = debounce(
  () => emit("update:input", inputElementRef.value?.value ?? ""),
  500,
);

watch(
  () => props.focus,
  (newValue) => {
    if (newValue) {
      inputElementRef.value?.focus();
    } else {
      inputElementRef.value?.blur();
    }
  },
);
</script>
<template>
  <div class="text-input__container">
    <form class="text-input__form" @submit.prevent="submit">
      <input
        ref="inputElementRef"
        type="text"
        :value="input"
        :placeholder="t('controlPlane.textInput.searchPlaceholder')"
        @focusin="() => emit('focus:in')"
        @focusout="() => emit('focus:out')"
        @keydown.escape="() => emit('focus:out')"
        @keydown.enter="
          (event) => {
            input = ((event as KeyboardEvent)?.target as HTMLInputElement)
              ?.value;
            debouncedInput();
          }
        "
      />
    </form>
    <div class="text-input__filters">
      <div class="filters__filter">
        <v-select
          :label="t('controlPlane.textInput.filters.match.label')"
          density="compact"
          variant="outlined"
          hide-details
          v-model="model.match"
          :items="[
            {
              title: 'Any part of word',
              value: SignPuddleMatch.Anywhere,
            },
            {
              title: 'Start of word',
              value: SignPuddleMatch.Start,
            },
            {
              title: 'Exact word',
              value: SignPuddleMatch.Exact,
            },
          ]"
          @update:focused="() => emit('focus:in')"
        />
      </div>
      <div class="filters__filter">
        <v-select
          :label="t('controlPlane.textInput.filters.country')"
          density="compact"
          variant="outlined"
          hide-details
          v-model="selectedCountry"
          :items="signpuddle_countries"
          :item-props="itemPropsCountryCode"
          @update:focused="() => emit('focus:in')"
        />
      </div>
      <div class="filters__filter">
        <v-select
          label="Puddle"
          density="compact"
          variant="outlined"
          hide-details
          v-model="selectedPuddle"
          :items="
            [
              {
                code: 'sgn46',
                name: 'Dicionário Brasil',
              },
              {
                code: 'sgn116',
                name: 'Enciclopédia Brasil',
              },
              { code: 'sgn114', name: 'Literatura Brasil' },
              {
                code: 'sgn80',
                name: 'Project 2 Dictionary Sorting',
              },
            ] as PuddleInfo[]
          "
          :item-props="itemPropsPuddles"
          @update:focused="() => emit('focus:in')"
        />
      </div>
      <div class="filters__filter">
        <v-select
          label="Sort by"
          density="compact"
          variant="outlined"
          hide-details
          v-model="selectedSortBy"
          :items="[
            { title: 'Created At', value: 'created_at' },
            { title: 'Updated At', value: 'updated_at' },
            { title: 'Id', value: 'id' },
            { title: 'Sign', value: 'sign' },
            { title: 'User', value: 'user' },
          ]"
          @update:focused="() => emit('focus:in')"
        />
      </div>
      <div class="filters__filter">
        <v-select
          label="Sort order"
          density="compact"
          variant="outlined"
          hide-details
          v-model="selectedSortOrder"
          :items="[
            { title: 'Ascending', value: 'asc' },
            { title: 'Descending', value: 'desc' },
          ]"
          @update:focused="() => emit('focus:in')"
        />
      </div>
      <div class="filters__filter">
        <v-checkbox
          :label="t('controlPlane.textInput.filters.caseInsensitive')"
          density="compact"
          variant="outlined"
          hide-details
          v-model="model.caseInsensitive"
          @update:focused="() => emit('focus:in')"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.text-input__container {
  width: 100%;

  .text-input__form {
    width: 80%;
    margin: auto;

    input {
      width: 100%;
      height: 3rem;
      padding: 0.5rem;
      background-color: white;
      border-radius: 5px;
      border: 1px solid rgb(0, 0, 0, 0.3);

      &:focus {
        outline: none;
        border: 1px solid black;
      }
    }
  }

  .text-input__filters {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.8rem;
    padding: 0 0.5rem;
  }
}

@media (max-width: 600px) {
  .text-input__container {
    min-width: 80%;
  }
}
</style>
