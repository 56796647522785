import { LocalHostClient, type UserPreferences } from "@/api/localHostClient";
import {
  SignPuddle3Client,
  SignPuddleMatch,
  SortBy,
  SortOrder,
  type PuddleInfo,
} from "@/api/SignPuddle3Client";
import { textInputEvent } from "./eventBus";

export default class TextInputModel {
  private _client: SignPuddle3Client;
  private _localHostClient: LocalHostClient;
  private _input = "";
  private _onfocus = false;
  private _componentOnFocus: "textInput" | "filter" | undefined = undefined;
  private _wordMatch = {
    title: "Any part of word",
    value: SignPuddleMatch.Anywhere,
  };
  private _sort = {
    order: {
      title: "Ascending",
      value: SortOrder.Ascending,
    },
    by: {
      title: "Created At",
      value: SortBy.CreatedAt,
    },
  };
  limit = 1;

  puddles = {
    loading: false,
    items: [
      {
        code: "sgn46",
        name: "Dicionário Brasil",
      },
      {
        code: "sgn116",
        name: "Enciclopédia Brasil",
      },
      { code: "sgn114", name: "Literatura Brasil" },
      {
        code: "sgn80",
        name: "Project 2 Dictionary Sorting",
      },
    ] as PuddleInfo[],
    selected: {
      code: "sgn46",
      name: "Dicionário Brasil",
    } as PuddleInfo,
  };
  selectedCountry = { name: "Brazil", code: "BR" };
  caseInsensitive = true;

  constructor(client?: SignPuddle3Client, localHostClient?: LocalHostClient) {
    this._client = client ?? new SignPuddle3Client();
    this._localHostClient = localHostClient ?? new LocalHostClient();
    this._setFiltersWithValuesFromLocalStorage();
  }

  get componentOnFocus() {
    return this._componentOnFocus;
  }

  set componentOnFocus(value: "textInput" | "filter" | undefined) {
    if (!value) {
      this._onfocus = false;
      this._componentOnFocus = value;
      this.emitState();
      return;
    }

    this._componentOnFocus = value;
    this._onfocus = true;
    this.emitState();
  }

  get input() {
    return this._input;
  }

  private _clearInput() {
    this._input = "";
  }

  set input(value: string) {
    if (value.length === 0) {
      this._clearInput();
      return;
    }

    this._input = value;
  }

  get wordMatch(): { title: string; value: SignPuddleMatch } {
    return this._wordMatch;
  }

  setWordMatch(value: string) {
    switch (value) {
      case SignPuddleMatch.Anywhere:
        this._wordMatch = {
          title: "Any part of word",
          value: SignPuddleMatch.Anywhere,
        };
        break;
      case SignPuddleMatch.Start:
        this._wordMatch = {
          title: "Start of word",
          value: SignPuddleMatch.Start,
        };
        break;
      case SignPuddleMatch.Exact:
        this._wordMatch = {
          title: "Exact word",
          value: SignPuddleMatch.Exact,
        };
        break;
    }

    this._updateLocalStorage({
      wordMatch: this._wordMatch.value,
    });
    this.emitState();
  }

  get sortBy() {
    return this._sort.by;
  }

  setSortBy(value: string) {
    switch (value) {
      case SortBy.CreatedAt:
        this._sort.by = {
          title: "Created At",
          value: SortBy.CreatedAt,
        };
        break;
      case SortBy.UpdatedAt:
        this._sort.by = {
          title: "Updated At",
          value: SortBy.UpdatedAt,
        };
        break;
      case SortBy.Id:
        this._sort.by = {
          title: "Id",
          value: SortBy.Id,
        };
        break;
      case SortBy.Sign:
        this._sort.by = {
          title: "Sign",
          value: SortBy.Sign,
        };
        break;
      case SortBy.User:
        this._sort.by = {
          title: "User",
          value: SortBy.User,
        };
        break;
    }

    this.emitState();
  }

  get sortByItems() {
    return [
      {
        title: "Created At",
        value: SortBy.CreatedAt,
      },
      {
        title: "Updated At",
        value: SortBy.UpdatedAt,
      },
      {
        title: "Id",
        value: SortBy.Id,
      },
      {
        title: "Sign",
        value: SortBy.Sign,
      },
      {
        title: "User",
        value: SortBy.User,
      },
    ];
  }

  get sortOrder() {
    return this._sort.order;
  }

  setSortOrder(value: string) {
    switch (value) {
      case SortOrder.Ascending:
        this._sort.order = {
          title: "Ascending",
          value: SortOrder.Ascending,
        };
        break;
      case SortOrder.Descending:
        this._sort.order = {
          title: "Descending",
          value: SortOrder.Descending,
        };
        break;
    }

    this.emitState();
  }

  get sortOrderItems() {
    return [
      {
        title: "Ascending",
        value: SortOrder.Ascending,
      },
      {
        title: "Descending",
        value: SortOrder.Descending,
      },
    ];
  }

  async getCountryPuddles(selectedCountry: string): Promise<PuddleInfo[]> {
    let puddles: PuddleInfo[] = [];
    this.puddles.loading = true;
    this.selectedCountry.code = selectedCountry;

    const countryPuddles =
      this._localHostClient.getCountryPuddles(selectedCountry);
    if (countryPuddles) {
      console.debug(
        "TextInput: getCountryPuddles: Puddles found in local host for country code",
        selectedCountry,
      );
      puddles = countryPuddles;
    }

    const response = await this._client.getCountryPuddles(selectedCountry);
    if (response) {
      const responsePuddles = response.results.flatMap(
        (result) => result.puddles,
      );
      this._localHostClient.setCountryPuddles(selectedCountry, responsePuddles);
      puddles = responsePuddles;
    }

    if (puddles.length === 0) {
      console.debug("TextInput: getCountryPuddles: No country puddles found.");
    }

    this.puddles.loading = false;
    this.puddles.items = puddles;
    this.puddles.selected = puddles[0];

    this._updateLocalStorage({
      country: {
        code: this.selectedCountry.code,
        name: this.selectedCountry.name,
        puddle: this.puddles.selected,
      },
    });
    this.emitState();
    return puddles;
  }

  setPuddleCode(value: { name: string; code: string }) {
    this.puddles.selected.name = value.name;
    this.puddles.selected.code = value.code;
    this._updateLocalStorage({
      country: {
        code: this.selectedCountry.code,
        name: this.selectedCountry.name,
        puddle: this.puddles.selected,
      },
    });
    this.emitState();
  }

  setCaseInsensitive(value: boolean | null) {
    if (null === value) {
      console.debug("TextInput: setCaseInsensitive: value is null");
      return;
    }

    this.caseInsensitive = value;
    this.emitState();
  }

  submit(event: Event) {
    event.preventDefault();
    this._onfocus = false;
    this._componentOnFocus = undefined;
    this.emitState(this.input);
    this._clearInput();
  }

  emitState(input?: string) {
    textInputEvent.emit("update", {
      apiConfigurations: {
        countryCode: this.selectedCountry.code,
        puddleCode: this.puddles.selected.code,
        match: this.wordMatch.value,
        caseInsensitive: this.caseInsensitive,
        sort: {
          by: this.sortBy.value,
          order: this.sortOrder.value,
        },
        limit: this.limit,
      },
      focused: this._onfocus,
      input: input,
    });
  }

  private _setFiltersWithValuesFromLocalStorage() {
    const latestPreferences = this._localHostClient.getUserPreferences();
    if (!latestPreferences) return;

    if (latestPreferences.wordMatch) {
      this.setWordMatch(latestPreferences.wordMatch);
    }

    if (latestPreferences.country) {
      this.selectedCountry.code = latestPreferences.country.code;
      this.selectedCountry.name = latestPreferences.country.name;
      this.puddles.selected = latestPreferences.country.puddle;
    }

    const countryPuddles = this._localHostClient.getCountryPuddles(
      this.selectedCountry.code,
    );
    if (countryPuddles) {
      this.puddles.items = countryPuddles;
    }
  }

  private _updateLocalStorage(preferences: UserPreferences) {
    const current = this._localHostClient.getUserPreferences();
    this._localHostClient.setUserPreferences({
      ...current,
      ...preferences,
    });
  }
}
