import type { ApiConfigurations } from "@/api/SignPuddle3Client";
import ContentManager from "./ContentManager/model";
import HistoryManager from "./ContentManager/HistoryManager/model";
import { info } from "@sutton-signwriting/core/fsw/fsw";
import documentEditorState, { type DocumentEditorState } from "./state";
import ColumnItemFactory from "./ContentManager/Paginator/Page/Column/ColumnItem/ColumnItemFactory";
import ColumnItem, {
  ColumnItemType,
} from "./ContentManager/Paginator/Page/Column/ColumnItem/model";
import { LocalHostClient } from "@/api/localHostClient";
import { signEditorEvent } from "./SignEditor/eventBus";

export type DocumentMetadata = {
  id: string;
  title: string;
  createdAt: string;
  lastModified: string;
};

export default class DocumentEditorModel {
  private _id: string;
  metadata: DocumentMetadata;
  content: ColumnItem[];
  contentManager: ContentManager;
  private _historyManager: HistoryManager;
  apiConfigurations: ApiConfigurations;
  state: DocumentEditorState;
  private localHostClient: LocalHostClient;

  constructor(configuration: {
    id: string;
    contentManager?: ContentManager;
    historyManager?: HistoryManager;
    state?: DocumentEditorState;
    apiConfigurations?: ApiConfigurations;
    localHostClient?: LocalHostClient;
  }) {
    this.localHostClient =
      configuration.localHostClient ?? new LocalHostClient();
    this._id = configuration.id;
    this.metadata =
      this.localHostClient.getDocumentMetadataById(this._id) ??
      ({} as DocumentMetadata);
    this.content = this.localHostClient.getDocumentContentById(this._id) ?? [];
    this.contentManager =
      configuration.contentManager ||
      new ContentManager({ content: this.content, documentId: this._id });
    this._historyManager = configuration.historyManager || new HistoryManager();
    this.state = configuration.state || documentEditorState;
    this.apiConfigurations = configuration.apiConfigurations || {
      limit: 1,
    };
  }

  /**
   * The actions performed within this method, should be performed
   * inside the constructor. However, to activate the reactivity
   * system and tie the subsequent calls to the Proxy, I needed
   * to extract it out from there.
   */
  init() {
    this.contentManager.paginate();

    const thereIsNoContentSnapshot = this._historyManager.history.length === 0;
    if (thereIsNoContentSnapshot) {
      this._historyManager.commit(this.contentManager.content);
    }
  }

  undo() {
    const previousContent = this._historyManager.undo();
    if (previousContent) {
      this._reProcess(previousContent);
    }
  }

  redo() {
    const nextContent = this._historyManager.redo();
    if (nextContent) {
      this._reProcess(nextContent);
    }
  }

  get canUndo() {
    return this._historyManager.canUndo;
  }

  get canRedo() {
    return this._historyManager.canRedo;
  }

  /**
   * Necessary to set this method because linter was
   * complaining about "Unexpected mutation of "model" prop".
   */
  setZoom(zoom: number) {
    this.state.global.zoom = zoom;
  }

  toggleNonPrintableCharacters() {
    this.state.items.showNonPrintableCharacters =
      !this.state.items.showNonPrintableCharacters;
  }

  signEditor() {
    return {
      handleUpdateEvent: (updatedSigns: { id: string; content: {} }[]) =>
        this._signEditorHandleUpdateEvent(updatedSigns),
      handleCreateEvent: (
        fsw: string,
        terms?: string,
        text?: string,
        source?: string,
      ) => this._signEditorHandleCreateEvent(fsw, terms, text, source),
      handleCloseEvent: () => this._signEditorHandleCloseEvent(),
      signMaker: () => this._signEditorSignMaker(),
      signPuddle: () => this._signEditorSignPuddle(),
    };
  }

  private _signEditorSignMaker() {
    this.state.global.elementOnFocus = "signEditor";

    return {
      create() {
        signEditorEvent.emit("activate", { app: "signmaker", mode: "create" });
      },
      update(signs?: ColumnItem[]) {
        signEditorEvent.emit("activate", {
          app: "signmaker",
          mode: "update",
          signs,
        });
      },
    };
  }

  private _signEditorSignPuddle() {
    this.state.global.elementOnFocus = "signEditor";

    return {
      create() {
        signEditorEvent.emit("activate", { app: "signpuddle", mode: "create" });
      },
      update(signs?: ColumnItem[]) {
        signEditorEvent.emit("activate", {
          app: "signpuddle",
          mode: "update",
          signs,
        });
      },
    };
  }

  private _signEditorHandleCreateEvent(
    fsw: string,
    terms?: string,
    text?: string,
    source?: string,
  ) {
    console.log(fsw, terms, text, source, "create");
    this.state.items.selected = [];
    this.addItem(ColumnItemType.SIGN, { fsw, terms, text, source });
  }

  private _signEditorHandleUpdateEvent(
    updatedSigns: { id: string; content: {} }[],
  ) {
    this.state.items.selected = [];
    updatedSigns.forEach((sign) => {
      this.contentManager.updateItem(
        sign.id,
        ColumnItemType.SIGN,
        sign.content,
      );
    });
  }

  private _signEditorHandleCloseEvent() {
    this.state.items.selected = [];
    this.state.global.elementOnFocus = "pages";
    this.contentManager.caret.show();
  }

  addItem(type: ColumnItemType, content?: {}) {
    const columnItem = this.contentManager.addItem(type, content);
    this._historyManager.commit(this.contentManager.content);
    return columnItem;
  }

  translateInputIntoSigns(text: string) {
    const isNotFsw = info(text).segment === "none";

    if (isNotFsw) {
      this.contentManager.translateTextIntoSigns(text, this.apiConfigurations);
      return;
    }

    const FSWs = text.split(" ");
    FSWs.forEach((fsw) => {
      this.addItem(ColumnItemType.SIGN, { fsw });
    });
  }

  deleteItemBackward() {
    this.contentManager.deleteItemBackward();
    this._historyManager.commit(this.contentManager.content);
  }

  updateItem(id: string, newType: ColumnItemType, content: object) {
    this.contentManager.updateItem(id, newType, content);
    this._historyManager.commit(this.contentManager.content);
  }

  async handleKeyDown(event: KeyboardEvent) {
    if (event.key.toLowerCase() === "v") {
      if (event.ctrlKey || event.metaKey) {
        event.preventDefault();

        const pastedText = await navigator.clipboard.readText();
        this.translateInputIntoSigns(pastedText);
        return;
      }
    }

    if (this.contentManager.caret.state.isActive) {
      if (event.key === "ArrowUp") {
        /**
         * ArrowUp and Shift key pressed means that the user wants to select
         * the items between the current caret position and the previous one.
         */
        if (event.shiftKey) {
          event.preventDefault();
          this.contentManager.caret.hide();

          if (this.contentManager.caret.state.position.beforeTarget) {
            /**
             * If the item before the target is already selected, it needs to be unselected.
             */
            if (
              this.state.items.selected.length > 0 &&
              this.state.items.selected.includes(
                this.contentManager.caret.itemBeforeTarget?.id ?? "",
              )
            ) {
              this.contentManager.caret.backward();
              this.state.items.selected.pop();
              return;
            }

            this.contentManager.caret.backward();
            this.selectItem(this.contentManager.caret.state.target?.id!);
            return;
          }

          if (this.contentManager.caret.state.position.afterTarget) {
            /**
             * If the current target is already selected, it needs to be unselected.
             */
            if (
              this.state.items.selected.length > 0 &&
              this.state.items.selected.includes(
                this.contentManager.caret.state.target?.id ?? "",
              )
            ) {
              this.contentManager.caret.backward();
              this.state.items.selected.pop();
              return;
            }

            this.selectItem(this.contentManager.caret.state.target?.id!);
            this.contentManager.caret.backward();
            return;
          }
        }

        /**
         * ArrowKey pressed, Shift key not pressed and there are items selected
         * it means that the user wants to undo the selection.
         */
        if (this.state.items.selected.length > 0) {
          event.preventDefault();
          this.contentManager.caret.show();
          this.state.items.selected = [];
        }

        if (event.metaKey) {
          event.preventDefault();
          this.contentManager.caret.startOfColumn();
          return;
        }

        event.preventDefault();
        this.contentManager.caret.backward();
        return;
      }

      if (event.key === "ArrowDown") {
        if (event.metaKey) {
          event.preventDefault();
          this.contentManager.caret.endOfColumn();
          return;
        }

        /**
         * ArrowDown and Shift key pressed means that the user wants to select
         * the items between the current caret position and the next one.
         */
        if (event.shiftKey) {
          event.preventDefault();
          this.contentManager.caret.hide();

          if (this.contentManager.caret.state.position.beforeTarget) {
            this.selectItem(this.contentManager.caret.state.target?.id!);
            this.contentManager.caret.forward();
            return;
          }

          if (this.contentManager.caret.state.position.afterTarget) {
            /**
             * If the item after the target is already selected, it needs to be unselected.
             */
            if (
              this.state.items.selected.length > 0 &&
              this.state.items.selected.includes(
                this.contentManager.caret.itemAfterTarget?.id ?? "",
              )
            ) {
              this.contentManager.caret.forward();
              this.state.items.selected.pop();
              return;
            }

            this.contentManager.caret.forward();
            this.selectItem(this.contentManager.caret.state.target?.id!);
            return;
          }
        }

        /**
         * ArrowKey pressed, Shift key not pressed and there are items selected
         * it means that the user wants to undo the selection.
         */
        if (this.state.items.selected.length > 0) {
          event.preventDefault();
          this.contentManager.caret.show();
          this.state.items.selected = [];
        }

        event.preventDefault();
        this.contentManager.caret.forward();
        return;
      }

      if (event.key === "ArrowLeft") {
        if (event.shiftKey && this.state.items.selected.length > 0) return;

        /**
         * ArrowKey pressed, Shift key not pressed and there are items selected
         * it means that the user wants to undo the selection.
         */
        if (this.state.items.selected.length > 0) {
          event.preventDefault();
          this.contentManager.caret.show();
          this.state.items.selected = [];
        }

        event.preventDefault();
        this.contentManager.caret.moveToPreviousColumn();
        return;
      }

      if (event.key === "ArrowRight") {
        if (event.shiftKey && this.state.items.selected.length > 0) return;

        /**
         * ArrowKey pressed, Shift key not pressed and there are items selected
         * it means that the user wants to undo the selection.
         */
        if (this.state.items.selected.length > 0) {
          event.preventDefault();
          this.contentManager.caret.show();
          this.state.items.selected = [];
        }

        event.preventDefault();
        this.contentManager.caret.moveToNextColumn();
        return;
      }

      if (event.key.toLowerCase() === "z") {
        if (event.ctrlKey && event.shiftKey) {
          event.preventDefault();
          this.redo();
          return;
        }

        if (event.ctrlKey && !event.shiftKey) {
          event.preventDefault();
          this.undo();
          return;
        }
      }

      if (event.key.toLowerCase() === "backspace") {
        event.preventDefault();

        if (this.state.items.selected.length > 0) {
          this.contentManager.deleteItems(this.state.items.selected);
          this.state.items.selected = [];
          this.contentManager.caret.show();
          return;
        }

        this.deleteItemBackward();
        return;
      }

      if (event.key.toLowerCase() === "delete") {
        event.preventDefault();
        this.contentManager.deleteItemForward();
      }

      if (event.key === "Enter") {
        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          this.addItem(ColumnItemType.BREAK_PAGE);
          return;
        }

        event.preventDefault();
        this.addItem(ColumnItemType.BREAK_FLOW);
        return;
      }

      if (event.key === " ") {
        event.preventDefault();
        this.addItem(ColumnItemType.SPACE);
        return;
      }

      if (event.key === ",") {
        event.preventDefault();
        this.addItem(ColumnItemType.SIGN_PUNCTUATION, {
          type: "comma",
        });
        return;
      }

      if (event.key === ".") {
        event.preventDefault();
        this.addItem(ColumnItemType.SIGN_PUNCTUATION, {
          type: "period",
        });
        return;
      }

      if (event.key === "?") {
        event.preventDefault();
        this.addItem(ColumnItemType.SIGN_PUNCTUATION, {
          type: "questionMark",
        });
        return;
      }

      if (event.key === "!") {
        event.preventDefault();
        this.addItem(ColumnItemType.SIGN_PUNCTUATION, {
          type: "exclamationMark",
        });
        return;
      }

      if (event.key === "(") {
        event.preventDefault();
        this.addItem(ColumnItemType.SIGN_PUNCTUATION, {
          type: "openParenthesis",
        });
        return;
      }

      if (event.key === ")") {
        event.preventDefault();
        this.addItem(ColumnItemType.SIGN_PUNCTUATION, {
          type: "closeParenthesis",
        });
        return;
      }

      if (event.key === ":") {
        event.preventDefault();
        this.addItem(ColumnItemType.SIGN_PUNCTUATION, {
          type: "colon",
        });
        return;
      }
    }
  }

  selectItem(id: string) {
    if (this.state.items.selected.includes(id)) return;

    this.state.items.selected.push(id);
  }

  private _reProcess(content: ColumnItem[]) {
    this.contentManager.content = [];

    content.forEach((item) => {
      const columnItem = ColumnItemFactory.createColumnItem(
        item.type,
        item.content,
      );
      this.contentManager.content.push(columnItem);
    });

    this.init();
  }
}
