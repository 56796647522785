<script setup lang="ts">
import TextInputModel from "./model";
import { onMounted, reactive, ref, watch } from "vue";
import { SignPuddleMatch, type PuddleInfo } from "@/api/SignPuddle3Client";
import { useI18n } from "vue-i18n";
import signpuddle_countries from "@/assets/jsons/signpuddle_countries.json";
import { documentEditorEventBus } from "@/views/DocumentEditor/eventBus";

const { t } = useI18n();

const props = defineProps<{ model?: TextInputModel }>();

const model = reactive(props.model ?? new TextInputModel());
const inputElementRef = ref<HTMLInputElement | null>(null);

function itemPropsPuddles(item: PuddleInfo) {
  return {
    title: item.name,
    subtitle: "",
  };
}

function itemPropsCountryCode(item: { name: string; code: string }) {
  return {
    title: item.code,
    subtitle: item.name,
  };
}

watch(
  () => model.componentOnFocus,
  (newValue) => {
    if (newValue === "textInput") {
      inputElementRef.value?.focus();
    } else {
      inputElementRef.value?.blur();
    }
  },
);

documentEditorEventBus({
  update: ({ focusOn, eventValue }) => {
    if (focusOn === "textInput") {
      model.componentOnFocus = focusOn;
      model.input += eventValue;
      model.emitState();
      return;
    }
  },
});

onMounted(() => {
  model.emitState();
});
</script>
<template>
  <div class="text-input__container">
    <form class="text-input__form" @submit="model.submit">
      <input
        ref="inputElementRef"
        type="text"
        :value="model.input"
        :placeholder="t('controlPlane.textInput.searchPlaceholder')"
        @input="
          (event) => (model.input = (event.target as HTMLInputElement).value)
        "
        @focusin="model.componentOnFocus = 'textInput'"
        @focusout="model.componentOnFocus = undefined"
        @keydown.escape="model.componentOnFocus = undefined"
      />
    </form>
    <div class="text-input__filters">
      <div class="filters__filter">
        <v-select
          :label="t('controlPlane.textInput.filters.match.label')"
          density="compact"
          variant="outlined"
          hide-details
          v-model="model.wordMatch.title"
          :items="[
            {
              title: 'Any part of word',
              value: SignPuddleMatch.Anywhere,
            },
            {
              title: 'Start of word',
              value: SignPuddleMatch.Start,
            },
            {
              title: 'Exact word',
              value: SignPuddleMatch.Exact,
            },
          ]"
          @update:model-value="(value) => model.setWordMatch(value)"
          @update:focused="model.componentOnFocus = 'filter'"
        />
      </div>
      <div class="filters__filter">
        <v-select
          :label="t('controlPlane.textInput.filters.country')"
          density="compact"
          variant="outlined"
          hide-details
          v-model="model.selectedCountry"
          :items="signpuddle_countries"
          :item-props="itemPropsCountryCode"
          @update:model-value="(value) => model.getCountryPuddles(value.code)"
          @update:focused="model.componentOnFocus = 'filter'"
        />
      </div>
      <div class="filters__filter">
        <v-select
          label="Puddle"
          density="compact"
          variant="outlined"
          hide-details
          v-model="model.puddles.selected"
          :loading="model.puddles.loading"
          :items="model.puddles.items"
          :item-props="itemPropsPuddles"
          @update:model-value="(value) => model.setPuddleCode(value)"
          @update:focused="model.componentOnFocus = 'filter'"
        />
      </div>
      <div class="filters__filter">
        <v-select
          label="Sort by"
          density="compact"
          variant="outlined"
          hide-details
          v-model="model.sortBy.title"
          :items="model.sortByItems"
          @update:model-value="(value) => model.setSortBy(value)"
          @update:focused="model.componentOnFocus = 'filter'"
        />
      </div>
      <div class="filters__filter">
        <v-select
          label="Sort order"
          density="compact"
          variant="outlined"
          hide-details
          v-model="model.sortOrder.title"
          :items="model.sortOrderItems"
          @update:model-value="(value) => model.setSortOrder(value)"
          @update:focused="model.componentOnFocus = 'filter'"
        />
      </div>
      <div class="filters__filter">
        <v-checkbox
          :label="t('controlPlane.textInput.filters.caseInsensitive')"
          density="compact"
          variant="outlined"
          hide-details
          v-model="model.caseInsensitive"
          @update:model-value="(value) => model.setCaseInsensitive(value)"
          @update:focused="model.componentOnFocus = 'filter'"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.text-input__container {
  width: 100%;

  .text-input__form {
    width: 80%;
    margin: auto;

    input {
      width: 100%;
      height: 3rem;
      padding: 0.5rem;
      background-color: white;
      border-radius: 5px;
      border: 1px solid rgb(0, 0, 0, 0.3);

      &:focus {
        outline: none;
        border: 1px solid black;
      }
    }
  }

  .text-input__filters {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-top: 0.8rem;
    padding: 0 0.5rem;
  }
}

@media (max-width: 600px) {
  .text-input__container {
    min-width: 80%;
  }
}
</style>
