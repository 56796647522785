<script setup lang="ts">
import { onMounted, reactive } from "vue";

import { type SignMetadata } from "@/utils/types";
import SignWriting from "@/components/SignWriting/SignWriting.vue";
import SignPuddleApiInput from "@/components/SignPuddleApiInput/SignPuddleApiInput.vue";

import SignPuddleModel from "./model";

const props = defineProps<{
  model?: SignPuddleModel;
}>();

/* eslint-disable @typescript-eslint/no-unused-vars */
const emit = defineEmits({
  "update:fsw": (value: string) => true,
  "update:metadata": (value: { metadata: SignMetadata }) => true,
});
/* eslint-enable @typescript-eslint/no-unused-vars */

const model = reactive(props.model ?? new SignPuddleModel());
onMounted(() => {
  model.load();
});
</script>
<template>
  <div
    class="sign-puddle"
    style="
      display: flex;
      flex-direction: column;
      height: 700px;
      width: 100%;
      overflow: auto;
    "
  >
    <div class="sign-puddle__actions">
      <SignPuddleApiInput
        :input="model.input"
        :api-configurations="model.apiConfigurations"
        @update:api-configurations="
          (newConfig) => (model.apiConfigurations = newConfig)
        "
        @update:input="
          (value) => {
            model.input = value;
            model.load();
          }
        "
      />
    </div>
    <div style="overflow: scroll; height: 100%">
      <div
        style="
          margin: auto;
          width: min-content;
          height: 100%;
          place-content: center;
        "
        v-if="model.loading"
      >
        <v-progress-circular indeterminate />
      </div>
      <ul
        v-else
        style="
          list-style: none;
          display: flex;
          flex-direction: column;
          height: 100%;
          gap: 1rem;
        "
      >
        <li
          v-for="(sign, index) in model.signs"
          :key="index"
          style="
            display: grid;
            grid-template-columns: auto 70%;
            border: 1px solid black;
            border-radius: 5px;
            padding: 1rem;
          "
        >
          <SignWriting :fsw="sign.sign" />
          <div>
            <p><strong>Terms</strong>:&nbsp;{{ sign.terms.join(", ") }}</p>
            <p>
              <strong>Description:</strong>&nbsp;{{
                sign.text.length > 0 ? sign.text : "--"
              }}
            </p>
            <p>
              <strong>Author</strong>:&nbsp;{{
                sign.source.length > 0 ? sign.source : "--"
              }}
            </p>
            <div style="display: inline-flex; gap: 0.5rem; margin-top: 0.5rem">
              <v-btn
                variant="outlined"
                size="small"
                text="COPY SIGN"
                @click="emit('update:fsw', sign.sign)"
              />
              <v-btn
                variant="outlined"
                size="small"
                text="COPY EVERYTHING"
                @click="
                  emit('update:metadata', {
                    metadata: {
                      terms: sign.terms,
                      text: sign.text,
                      source: sign.source,
                      fsw: sign.sign,
                    },
                  })
                "
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
